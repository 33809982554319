"use client";

import Link from "next/link";
import { useState } from "react";
import type { ComponentProps, FormEvent } from "react";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/nextjs";
import { Search } from "lucide-react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { Navigation } from "./Navigation";
import { Logo } from "@/components/typography/Logo";
import { PersonOutline } from "@/components/icons";
// import { useConsent } from "@/lib/hooks/useConsent";
import { cn } from "@/lib/utils";

const Header = ({ ...rest }: ComponentProps<"header">) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  // const { statistics } = useConsent();
  const pathname = usePathname();

  const router = useRouter();
  const searchParams = useSearchParams();
  const query = searchParams.get("q") || undefined;

  // useEffect(() => {
  //   // see https://posthog.com/tutorials/delayed-survey
  //   const timeoutId = setTimeout(() => {
  //     const header = document.querySelector("header");
  //     if (header && statistics) {
  //       header.classList.add("delayed-survey");
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timeoutId);
  // }, [statistics]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const queryTerm = formData.get("query");
    router.push("/search?q=" + queryTerm);
  };

  return (
    <header
      className={cn(
        "bg-sand-50 p-4 md:px-16 md:py-8",
        {
          ["bg-gray-10"]:
            pathname === "/about" || pathname.startsWith("/courses"),
          ["bg-sand-100"]: pathname.startsWith("/events"),
          ["bg-sky-100"]: pathname === "/partner/hcp",
          ["bg-sun-100"]: pathname === "/partner/b2b",
          ["bg-transparent z-20 absolute w-full"]: pathname === "/library",
        },
        rest.className
      )}
    >
      <div className="container max-w-7xl mx-auto flex justify-between items-center group">
        <Link
          href="/"
          className={cn(
            "flex-grow md:flex-grow-0 transition-all duration-300 ease-in-out",
            {
              ["hidden md:block"]: isSearchFocused,
            }
          )}
          aria-label="Dialetics"
        >
          <Logo />
        </Link>

        <Navigation />

        <div
          className={cn("flex items-center space-x-2", {
            [""]: isSearchFocused,
          })}
        >
          <form
            method="get"
            action="/search"
            onSubmit={handleSubmit}
            className="relative"
          >
            <input
              type="search"
              name="query"
              placeholder="Suche"
              className="pl-8 md:pr-2 py-1 h-10 w-10 md:w-auto border border-transparent rounded-full transition-all duration-300 ease-in-out focus:w-auto md:focus:w-auto"
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
            />
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-black w-4 h-4" />
          </form>

          <SignedIn>
            <Link href="/library">
              <button className="bg-black text-white text-xs px-4 py-2 rounded-full h-10 hidden md:flex items-center">
                Zu den Kursen
              </button>
            </Link>
            <UserButton />
          </SignedIn>
          <SignedOut>
            <Link href="/onboarding/user">
              <button className="bg-black text-white text-xs px-4 py-2 rounded-full h-10 hidden md:flex items-center">
                Kostenlos anmelden
              </button>
            </Link>
            <div className="rounded-full bg-sun-100 w-10 h-10 flex items-center justify-center">
              <SignInButton mode="modal">
                <PersonOutline className="w-4 h-4" />
              </SignInButton>
            </div>
          </SignedOut>
        </div>
      </div>
    </header>
  );
};

export { Header };
