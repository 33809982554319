import type { ComponentProps } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import {
  Tabs as TabsComponent,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { Headline } from "@/components/typography/Headline";
import type { TabsStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type TabsProps = ComponentProps<"section"> & {
  blok: TabsStoryblok;
};

const Tabs = ({ blok, ...rest }: TabsProps) => (
  <section
    className={cn("p-4 md:p-14", rest.className)}
    {...storyblokEditable(blok)}
  >
    <header className="mb-16">
      <Headline as="h2" size="tall" className="max-w-4xl">
        {blok.headline}
      </Headline>
    </header>

    <TabsComponent defaultValue={blok.items?.[0]?._uid}>
      <TabsList className="w-full justify-center rounded-none bg-transparent p-0">
        {blok.items?.map((nestedBlok: any) => (
          <TabsTrigger
            value={nestedBlok._uid}
            key={`tab-trigger-${nestedBlok._uid}`}
            className="rounded-full border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none data-[state=active]:border-b-transparent data-[state=active]:text-foreground data-[state=active]:shadow-none"
          >
            {nestedBlok.headline}
          </TabsTrigger>
        ))}
      </TabsList>

      {blok.items?.map((nestedBlok: any) => (
        <TabsContent
          value={nestedBlok._uid}
          key={`tab-content-${nestedBlok._uid}`}
        >
          <StoryblokComponent blok={nestedBlok} />
        </TabsContent>
      ))}
    </TabsComponent>

    <div className="flex justify-center">
      {blok.cta?.map((nestedBlok: any) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default Tabs;
