import Image from "next/image";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { cn } from "@/lib/utils";

import { Headline } from "@/components/typography/Headline";
import type { HeaderImageStoryblok } from "@/component-types-sb";

type HeaderImageProps = {
  blok: HeaderImageStoryblok;
  className?: string;
};

const HeaderImage = ({ blok, ...rest }: HeaderImageProps) => (
  <section {...storyblokEditable(blok)} className="md:h-[640px] relative">
    <Image
      src={blok.image.filename}
      alt={blok.image.alt || ""}
      className={cn("w-full h-32 md:h-full object-cover", rest.className)}
      width="3000"
      height="2000"
      style={{
        aspectRatio: "3/2",
        objectFit: "cover",
      }}
      priority
    />
    <div
      className={cn(
        "md:absolute inset-0 left-0 top-0 flex flex-col justify-center bg-gray-200 md:bg-transparent backdrop-brightness-60 py-4",
        {
          "justify-end": blok.align == "bottom",
        }
      )}
    >
      <div className="max-w-7xl mx-auto px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-8">
        <div>
          {blok.headline && (
            <Headline as="h2" size="tall" className="text-white font-bold">
              {blok.headline}
            </Headline>
          )}

          <p className="my-8 text-2xl font-semibold md:text-white">
            {blok.text}
          </p>

          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default HeaderImage;
