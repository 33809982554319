import Link from "next/link";
import type { ComponentProps } from "react";
import type { ButtonStoryblok } from "@/component-types-sb";

import { Headline } from "@/components/typography/Headline";
import { Button } from "@/components/ui/button";
import { Shape2 } from "@/components/illustrations/Shape2";
import { Shape3 } from "@/components/illustrations/Shape3";
import { cn } from "@/lib/utils";

export type HeroShape = "shape2" | "shape3";
export type HeroColor = "sand" | "sand-100" | "sky" | "sun";

type HeroProps = {
  headline: string;
  cta?: ButtonStoryblok;
  shape?: HeroShape;
  color?: HeroColor;
} & ComponentProps<"section">;

const Hero = ({
  headline,
  cta,
  shape = "shape3",
  color = "sand",
  ...rest
}: HeroProps) => {
  const Shape = shape === "shape3" ? Shape3 : Shape2;

  return (
    <section
      className={cn(
        "p-16",
        { "bg-sand-50": color === "sand" },
        { "bg-sand-100": color === "sand-100" },
        { "bg-sky-100": color === "sky" },
        { "bg-sun-100": color === "sun" },
        rest.className
      )}
    >
      <div className="container max-w-7xl mx-auto flex flex-row justify-between items-center">
        <div>
          <Headline
            as="h1"
            size="huge"
            className={cn("max-w-lg mb-4 ", {
              "text-white": color === "sky",
            })}
          >
            {headline}
          </Headline>
          <Link href={`/${cta?.link?.cached_url}` || "#"}>
            <Button
              size="lg"
              className={cn("rounded-full text-xl h-16 px-8 mt-12", {
                "bg-white text-black hover:bg-white": color === "sky",
              })}
            >
              {cta?.label}
            </Button>
          </Link>
        </div>

        <Shape
          className={cn(
            "max-h-[272px]",
            { "text-sun-100": color === "sand" || color === "sand-100" },
            { "text-sky-25": color === "sky" },
            { "text-sky-100": color === "sun" }
          )}
        />
      </div>
    </section>
  );
};

export { Hero };
