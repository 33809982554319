"use client";

import { useEffect } from "react";
import type { ReactNode } from "react";
import { GoogleAnalytics } from "@next/third-parties/google";
import { NuqsAdapter } from "nuqs/adapters/next/app";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import type { FeatureFlags } from "@/lib/feature-flags";
import { FeaturesContext } from "@/lib/hooks/useFeature";
import { useConsent } from "@/lib/hooks/useConsent";

// Check that PostHog is client-side (used to handle Next.js SSR)
// see https://posthog.com/docs/libraries/next-js#app-router
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: "/ingest",
    ui_host: "https://us.posthog.com",
    persistence: "memory",
    // Enable debug mode in development or preview
    loaded: (posthog) => {
      if (process.env.VERCEL_ENV !== "production") {
        posthog.debug();
      }
    },
  });
}

type Props = {
  readonly features: FeatureFlags;
  readonly children: ReactNode;
};

export const Providers = ({ features, children }: Props) => {
  const { statistics } = useConsent();

  useEffect(() => {
    posthog.set_config({
      persistence: statistics ? "localStorage+cookie" : "memory",
      disable_persistence: !statistics,
    });
  }, [statistics]);

  return (
    <FeaturesContext.Provider value={features}>
      <PostHogProvider client={posthog}>
        <NuqsAdapter>
          {children}
          {statistics && (
            <GoogleAnalytics
              gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID!}
            />
          )}
        </NuqsAdapter>
      </PostHogProvider>
    </FeaturesContext.Provider>
  );
};
